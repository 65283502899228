export default class Global {
    public static apiServer: string = "https://fwbeuern.jcraft.de";
    public static apiPath: string = "/api";
    public static socketPath: string = "/api/socket.io";

    //dev settings
    /*public static apiServer: string = "http://127.0.0.1:3000";
    public static apiPath: string = "";
    public static socketPath: string = "/socket.io";*/

}