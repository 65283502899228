import Vue, { createApp } from 'vue'
import Vuex, { createStore } from 'vuex'
import App from './App.vue'
import './assets/tailwind.css'
import VueCookies from 'vue-cookies'
import 'flowbite';
import router from './router'

const store = createStore({})
const app = createApp(App);
app.use(VueCookies);
app.use(store);
app.use(router);
app.mount('#app')
