import { createWebHistory, createRouter } from 'vue-router';
import Home from '../views/Home.vue'
import { VueCookies } from 'vue-cookies'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  /*base: process.env.BASE_URL,*/
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && false) next({ name: 'Login' })
  else next()
})

export default router
